// @flow
// $FlowFixMe
import {Validation} from '@wellstone-solutions/common';
import moment from 'moment';

const {isOptional, isRequired, dateIsBefore} = Validation;

const maxScheduledDate = moment().add(720, 'hours').toDate();
// $FlowFixMe
const validateDateIsBefore = dateIsBefore(maxScheduledDate);

export const schema = {
  content: isRequired,
  id: isOptional,
  scheduled: {
    validate: validateDateIsBefore,
    error: "Announcements can't be scheduled more than 30 days in advance.",
  },
  status: isRequired,
  title: isRequired,
  author: isRequired,
};
