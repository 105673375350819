// @flow
import type {Node} from 'react';
// $FlowFixMe
import {Stack} from '@wellstone-solutions/web';
import {PublishBarDraftButtons} from './PublishBarDraftButtons';
import {PublishBarPublishedButtons} from './PublishBarPublishedButtons';

type PropsType = {
  formId: string,
  handlePublishNow: () => void,
  handlePublishConfirmation: () => mixed,
  handleCancelPublish: () => void,
  handleUnpublish: () => mixed,
  handleConfirmUnpublish: () => mixed,
  handleCancelUnpublish: () => void,
  handleRevertChanges: () => void,
  handleScheduleConfirmation: () => mixed,
  hasErrors: boolean,
  isDraft: boolean,
  isPublished: boolean,
  isPublishing: boolean,
  isUnpublishing: boolean,
  isScheduledInFuture: boolean,
  isScheduledState: boolean,
  isSubmitting: boolean,
  isTouched: boolean,
  recipientName: string,
};

export const PublishButtonBar = ({
  formId,
  handlePublishNow,
  handlePublishConfirmation,
  handleScheduleConfirmation,
  handleCancelPublish,
  handleUnpublish,
  handleConfirmUnpublish,
  handleCancelUnpublish,
  handleRevertChanges,
  hasErrors,
  isDraft,
  isPublished,
  isPublishing,
  isUnpublishing,
  isScheduledInFuture,
  isScheduledState,
  isSubmitting,
  isTouched,
  recipientName,
}: PropsType): Node => (
  <Stack flexDirection="row" sx={{mt: 2}}>
    {isDraft && (
      <PublishBarDraftButtons
        formId={formId}
        handlePublishNow={handlePublishNow}
        handlePublishConfirmation={handlePublishConfirmation}
        handleScheduleConfirmation={handleScheduleConfirmation}
        handleCancelPublish={handleCancelPublish}
        handleRevertChanges={handleRevertChanges}
        hasErrors={hasErrors}
        isPublishing={isPublishing}
        isScheduledInFuture={isScheduledInFuture}
        isSubmitting={isSubmitting}
        isTouched={isTouched}
        recipientName={recipientName}
      />
    )}
    {(isPublished || isScheduledState) && (
      <PublishBarPublishedButtons
        handleConfirmUnpublish={handleConfirmUnpublish}
        handleCancelUnpublish={handleCancelUnpublish}
        handleUnpublish={handleUnpublish}
        isUnpublishing={isUnpublishing}
        isSubmitting={isSubmitting}
        recipientName={recipientName}
      />
    )}
  </Stack>
);
