// @flow
import {Api} from '@wellstone-solutions/common';
import {routes} from 'api';
import type {
  ApiOptionsType,
  ApiResponseType,
} from '@wellstone-solutions/common';
import type {
  APIAnnouncementType,
  FetchAnnouncementsParamsType,
} from '../../types';
import {status} from '../../constants';

export const getAll = async (
  options?: ApiOptionsType<mixed, FetchAnnouncementsParamsType>,
): Promise<APIAnnouncementType[]> => {
  const params = options && options.params ? options.params : {};
  const {status, ...otherParams} = params;
  // Due to a peculiarity with django, we must pass "status" in the query string
  // as ?status=draft&status=published which is unconventional, the code below
  // handles this unique case.
  const url =
    status && status.length > 0
      ? `${routes.resources.announcements}?${status
          .map((s) => `status=${s}`)
          .join('&')}`
      : routes.resources.announcements;

  const response = await Api.Instance.current().get(url, {
    ...options,
    params: otherParams,
  });

  return response.data.announcements;
};

export const get = async (
  id: string,
): Promise<ApiResponseType<APIAnnouncementType>> => {
  return Api.Instance.current().get(
    `${routes.resources.announcements}/${String(id)}`,
  );
};

export const create = async (
  announcement: APIAnnouncementType,
): Promise<ApiResponseType<APIAnnouncementType>> =>
  Api.Instance.current().post(routes.resources.announcements, announcement);

export const cancel = async (
  announcement: APIAnnouncementType,
): Promise<ApiResponseType<APIAnnouncementType>> => {
  const {id} = announcement;

  return Api.Instance.current().delete(
    `${routes.resources.announcements}/${String(id)}`,
  );
};

export const update = async (
  announcement: APIAnnouncementType,
): Promise<ApiResponseType<APIAnnouncementType>> => {
  /* eslint-disable no-unused-vars */
  const {id, ...payload} = announcement;

  if (!id) {
    return Promise.reject('Cannot Update: Announcement does not have an id');
  }

  return Api.Instance.current().patch(
    `${routes.resources.announcements}/${id}`,
    payload,
  );
};

export const publish = async (
  id: string,
): Promise<ApiResponseType<APIAnnouncementType>> => {
  return Api.Instance.current().patch(
    `${routes.resources.announcements}/${id}`,
    {
      status: status.published,
    },
  );
};

export const unpublish = async (
  id: string,
): Promise<ApiResponseType<APIAnnouncementType>> => {
  return Api.Instance.current().patch(
    `${routes.resources.announcements}/${id}`,
    {
      status: status.draft,
    },
  );
};

export const schedule = async (
  id: string,
): Promise<ApiResponseType<APIAnnouncementType>> => {
  return Api.Instance.current().patch(
    `${routes.resources.announcements}/${id}`,
    {
      status: status.scheduled,
    },
  );
};
