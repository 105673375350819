// @flow
import {
  action,
  computed,
  observable,
  IObservableArray,
  IObservableValue,
  makeObservable,
  runInAction,
} from 'mobx';
import type {ApiResponseType} from '@wellstone-solutions/common';
import type {
  ApiResourceType,
  UIResourceType,
  UICollectionType,
} from '@wellstone-solutions/common/models/rest';
import {Collection, Resource} from '@wellstone-solutions/common/models/rest';
import {RootStore} from 'RootStore';

export class ResourceV2Store {
  resources: IObservableArray<UIResourceType> = observable.array();
  collections: IObservableArray<UICollectionType> = observable.array();
  _isLoading: IObservableValue<boolean> = observable.box(false);
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      collections: observable,
      resources: observable,
      _isLoading: observable,
      isLoading: computed,
      setIsLoading: action,
      getCollections: action,
      getResources: action,
      createResource: action,
      updateResource: action,
    });

    this.rootStore = rootStore;
  }

  setIsLoading(isLoading: boolean) {
    this._isLoading.set(isLoading);
  }

  get isLoading(): boolean {
    return this._isLoading.get();
  }

  async getCollections(
    forceFetch: boolean = false,
  ): Promise<ApiResponseType<IObservableArray<UICollectionType> | []>> {
    this.setIsLoading(true);
    const response = await Collection.getAll();
    if (response.isSuccess) {
      const resourceCollections = response.data.filter(
        (collection) => collection.collection_type.name === 'Resources',
      );

      const uiCollections = resourceCollections.map(Collection.toUI);
      runInAction(() => {
        this.collections.replace(uiCollections);
      });

      this.setIsLoading(false);

      return {...response, data: this.collections};
    } else {
      return {...response, data: []};
    }
  }

  async getResources(
    forceFetch: boolean = false,
  ): Promise<ApiResponseType<IObservableArray<UIResourceType> | []>> {
    this.setIsLoading(true);
    const response = await Resource.getAll();
    if (response.isSuccess) {
      const uiResources = response.data.map(Resource.toUI);
      runInAction(() => {
        this.resources.replace(uiResources);
      });

      this.setIsLoading(false);

      return {...response, data: this.resources};
    } else {
      return {...response, data: []};
    }
  }

  async createResource(
    resource: UIResourceType,
  ): Promise<ApiResponseType<UIResourceType>> {
    const payload = Resource.toApi(resource);
    const response = await Resource.create(payload);
    let uiResponse;

    if (response.isSuccess) {
      runInAction(() => {
        uiResponse = Resource.toUI(response.data);
        this.resources.push(uiResponse);
      });
    }

    // $FlowFixMe
    return uiResponse;
  }

  async updateResource(
    resource: UIResourceType,
  ): Promise<ApiResponseType<ApiResourceType>> {
    const {id} = resource;
    const payload = Resource.toApi(resource);
    const response = await Resource.update(id, payload);

    if (response.isSuccess) {
      runInAction(() => {
        const observable = this.resources.find((r) => r.id === resource.id);

        Object.assign(observable, Resource.toUI(response.data));
      });
    }

    return response;
  }
}
