// @flow
import React, {useEffect} from 'react';
import type {ComponentType, Node} from 'react';
import {styled} from '@mui/material/styles';
import moment from 'moment';
import {
  Button,
  DatePicker,
  Form as CommonForm,
  FormRow,
  ErrorMessage,
  RichTextEditor,
  Select,
  Stack,
  TextField,
  TimePicker,
  Typography,
  // $FlowFixMe
} from '@wellstone-solutions/web';
import {useToastUIImageUploadHandler} from 'hooks/useToastUIImageUploadHandler';
import {useStores} from 'hooks/useStores';
import {TEMPORARY_IMAGE_FOLDER} from 'constants/images';
import {PATHFINDER_ORG_NAME} from '../../constants';
import type {AnnouncementFormType} from '../../types';

type PropsType = {
  formId: string,
  form: AnnouncementFormType,
  isDisabled?: void | boolean,
};

const Overlay = styled('div')((props) =>
  props.disabled
    ? {
        position: 'relative',
        '*': {
          opacity: 0.9,
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background: 'rgba(0, 0, 0, 0.05)',
          zIndex: 100,
        },
      }
    : {},
);

export const Form: ComponentType<PropsType> = React.forwardRef(
  ({form, formId, isDisabled = false}: PropsType, ref: any): Node => {
    const {alertStore, announcementStore} = useStores();

    const handleImageUpload = useToastUIImageUploadHandler({
      folder: TEMPORARY_IMAGE_FOLDER,
      onError: () => {
        alertStore.addError('Something went wrong.  Please try again.');
      },
    });

    const authorOptions = [
      {
        value: PATHFINDER_ORG_NAME,
        label: PATHFINDER_ORG_NAME,
      },
    ];

    if (announcementStore.isRecipientOrganization) {
      // adds organization name to list of authors
      authorOptions.unshift({
        value: announcementStore.recipientName,
        label: announcementStore.recipientName,
      });
    }

    // Disables the content editor
    useEffect(() => {
      if (isDisabled && ref.current && ref.current.rootEl) {
        const contentEditor =
          ref.current.rootEl.current.querySelector('[contenteditable]');
        contentEditor?.removeAttribute('contenteditable');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDisabled]);

    const handleContentChange = () => {
      // $FlowFixMe
      const content = ref.current.getInstance().getMarkdown();
      form.setFieldTouched('content');
      form.setFieldValue('content', content);
    };

    const onScheduledChange = (value) => {
      form.setFieldTouched('scheduled');
      form.setFieldValue('scheduled', value);
    };

    return (
      <CommonForm
        data-testid="announcement-form"
        id={formId}
        onSubmit={form.handleSubmit}>
        <FormRow>
          <TextField
            formField="title"
            disabled={isDisabled}
            onChange={form.handleFieldChange('title')}
            value={form.values.title}
            label="Title"
          />
          <ErrorMessage
            name="title"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
        <FormRow>
          <Select
            label="Author"
            items={authorOptions}
            value={form.values.author}
            disabled={isDisabled}
            onChange={(evt) => {
              form.setFieldTouched('author');
              form.setFieldValue('author', evt.target.value);
            }}
            data-testid="select-author"
            canUnselect={false}
          />
          <ErrorMessage
            name="author"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
        <FormRow>
          <Typography variant="h7" sx={{fontWeight: 'bold', mb: 1}}>
            Content
          </Typography>
          <Overlay data-testid="editor-overlay" disabled={isDisabled}>
            <RichTextEditor
              initialValue={form.values.content}
              previewStyle="vertical"
              height="300px"
              usageStatistic={false}
              hideModeSwitch={false}
              initialEditType="wysiwyg"
              useCommandShortcut={true}
              toolbarItems={[
                [
                  'heading',
                  'bold',
                  'italic',
                  'hr',
                  'ul',
                  'ol',
                  'quote',
                  'link',
                  'image',
                ],
              ]}
              customImageHandler={handleImageUpload}
              onChange={handleContentChange}
              ref={ref}
            />
          </Overlay>
        </FormRow>
        <FormRow sx={{flexDirection: 'column'}}>
          <Stack flexDirection="row" sx={{gap: 2}}>
            <DatePicker
              disabled={isDisabled}
              fieldName="scheduled"
              label="Scheduled date"
              onChange={(date) => {
                // $FlowFixMe
                onScheduledChange(date?.isValid() ? date.toISOString() : '');
              }}
              value={form.values.scheduled}
              minDate={moment()}
            />
            <TimePicker
              disabled={isDisabled}
              fieldName="scheduledTime"
              label="Scheduled time"
              onChange={(time) => {
                // $FlowFixMe
                onScheduledChange(time?.isValid() ? time.toISOString() : '');
              }}
              value={form.values.scheduled}
            />
            <Button
              disabled={isDisabled}
              onClick={() => {
                onScheduledChange(moment().toISOString());
              }}
              variant="outlined"
              sx={{
                alignSelf: 'flex-start',
                mt: 1,
              }}>
              Schedule now
            </Button>
          </Stack>
          <ErrorMessage
            name="scheduled"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
      </CommonForm>
    );
  },
);

Form.displayName = 'Form';
